import React from "react";
import { useDispatch } from "react-redux";
import { ocrSaveTransactionIdAction } from "../../../ocr/ocr.actions";
import { OcrSuccess } from "../../../ocr/success/ocr-success";
import { useQueryString } from "../../../shared/gatsby.utils";
import { GatsbyPageProps } from "../../../types/gatsby";

const VerificationCreditPage = (props: GatsbyPageProps) => {
  const { transactionId } = useQueryString(props.location);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (transactionId) {
      dispatch(ocrSaveTransactionIdAction(transactionId.toString()));
    }
  }, [transactionId, dispatch]);

  return <OcrSuccess />;
};

export default VerificationCreditPage;
