import { StatusCallout } from "@wisr/react-ui";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormWrapper } from "../../forms/wrapper/wrapper-form.component";
import { appInsights } from "../../shared/insights/app-insights";
import {
  EXCEPTION_ERROR_EVENT,
  USER_ERROR,
} from "../../shared/insights/app-insights.consts";
import { createAppInsightsEvent } from "../../shared/insights/app-insights.utils";
import { ocrGetAction } from "../ocr.actions";
import { useGetOcrTransaction } from "../ocr.hooks";

export const OcrSuccess = () => {
  const dispatch = useDispatch();
  const ocrState = useGetOcrTransaction();
  const [formError, setFormError] = useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (ocrState.error) {
      setFormError(ocrState.error);
      appInsights.trackEvent(
        EXCEPTION_ERROR_EVENT,
        createAppInsightsEvent({
          category: USER_ERROR,
          info: ocrState.error,
          type: "OCR error",
        })
      );
    }
  }, [ocrState.error]);

  React.useEffect(() => {
    if (ocrState.transactionId) {
      dispatch(ocrGetAction());
    }
  }, [ocrState.transactionId, dispatch]);

  return (
    <div className="ocr-form">
      <FormWrapper
        hero={"Get your scores"}
        pageType={"credit"}
        formLayout={true}
        removeButton={true}
      >
        {formError && (
          <StatusCallout type="error" message={formError} theme={"outline"} />
        )}
        <div className="content">
          <h2>Hold tight</h2>
          <p>Please give us a second to grab your completed details.</p>
        </div>
      </FormWrapper>
    </div>
  );
};
