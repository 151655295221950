import { Button, FormLayout } from "@wisr/react-ui";
import React from "react";
import style from "./wrapper-form.scss";

interface formProps {
  formLayout: boolean;
  handleSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  children: React.ReactNode;
  hero: React.ReactNode;
  callout?: React.ReactNode;
  buttonText?: string;
  pageType: "credit" | "loans" | "roundup";
  disableSubmit?: boolean;
  removeButton?: boolean;
}

export const FormWrapper = (props: formProps) => {
  const {
    children,
    pageType,
    formLayout,
    handleSubmit,
    buttonText = "Submit",
    removeButton,
    hero,
    callout = <></>,
    disableSubmit,
  } = props;

  const colours = {
    credit: "orange",
    loans: "yellow",
    roundup: "blue",
  };

  const fallbackSubmit = () => {
    // do nothing
  };

  return formLayout ? (
    <div className="form-layout">
      <style jsx>{style}</style>
      <FormLayout
        hero={
          <>
            <h1 className="form-hero">{hero}</h1>
          </>
        }
        callout={callout}
        colour={colours[pageType] as any}
        buttons={
          !removeButton && (
            <Button theme="navy" disabled={disableSubmit}>
              <button type="submit" disabled={disableSubmit}>
                <span>{buttonText}</span>
              </button>
            </Button>
          )
        }
        onSubmit={handleSubmit ?? fallbackSubmit}
      >
        {children}
      </FormLayout>
    </div>
  ) : (
    <form noValidate onSubmit={handleSubmit}>
      {children}
    </form>
  );
};
